import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getAccumulatedOrderAmountSetting, addEditAccumulatedOrderAmountSetting } from '../actions/accumulated_order_amount_setting.actions';
import { addErrorMessages } from '../../messages/messages.actions';

import { ADMIN_URL } from '../../../constants';
import AccumulatedOrderAmountSettingForm from './AccumulatedOrderAmountSettingForm';
import { Tabs, Tab } from 'react-bootstrap';

class AccumulatedOrderAmountSettingPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.setting.id,
			percent: this.props.setting.discount_amount ? this.props.setting.discount_amount : '',
			// discount_type: this.props.setting.discount_type ? this.props.setting.discount_type : 'money',
			// on_order_number: this.props.setting.on_order_number ? this.props.setting.on_order_number : '',
			placeId: this.props.setting.placeId,
		}
	}

	componentWillMount() {
		this.props.getAccumulatedOrderAmountSetting();
	}
	
	componentWillReceiveProps(nextProps) {
		console.log(" RECEIVING", nextProps)
		if (nextProps.setting.id) {

			this.setState({
				id: nextProps.match.params.id,
				percent: nextProps.setting.percent,
			    placeId: nextProps.setting.placeId ? nextProps.setting.placeId : '',
			});
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
    }

	validate() {
		let errors = [];

		// if (this.state.discount_amount <= 0) {
		// 	errors.push(this.props.translations.messages.errors.invalid_discount_amount);
		// }
		// if (this.state.on_order_number <= 0) {
		// 	errors.push(this.props.translations.messages.errors.invalid_on_order_number);
		// }
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		const post = {
			id: this.state.id,
			percent: this.state.percent,
			placeId: this.state.placeId,
		}

		this.props.addEditAccumulatedOrderAmountSetting(post);
	}

	deleteRegion = (e, id) => {
		e.preventDefault();
		// this.props.addEditRegion({ id: id, deleted: true }, this.props.history);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.accumulated_order_amount_setting.title}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
                                <div className="card">

									<Tabs
										style={{ width: '100%'}}
										id="controlled-tab-example"
										activeKey={this.state.currentTab}
										onSelect={(k) => this.setState({ currentTab: k })}
									>
										<Tab eventKey="edit" title={this.props.translations.accumulated_order_amount_setting.title} >
											<div className="col-sm-12">
												<div className="card">

													<AccumulatedOrderAmountSettingForm
														onSubmit={this.onSubmit}
														onChange={this.onChange}
														setting={this.state}
														translations={this.props.translations}
													/>

												</div>
											</div>
										</Tab>

									</Tabs>	
								</div>
							</div>
						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {

	let setting = state.accumulatedOrderAmountSetting.setting;
	console.log(" TUK ", setting)
	if (!setting) setting = {
		id: '',
		percent: '',
		placeId: '',
	};
	return {
		setting: setting,
		translations: state.lang,
	}
};

export default connect(mapStateToProps, { getAccumulatedOrderAmountSetting, addEditAccumulatedOrderAmountSetting, addErrorMessages })(AccumulatedOrderAmountSettingPage);