import { LIST_ACCUMULATED_ORDER_AMOUNT_SETTING } from '../actions/types';

const initialState = {
    setting: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_ACCUMULATED_ORDER_AMOUNT_SETTING: {
            return {
                ...state,
                setting: action.payload,
            }
        }
       
        default:
            return state;
    }
}