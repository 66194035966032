import React from 'react';
import { Switch, Route, Router, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './store'
import DashboardPage from './components/DashboardPage';
// Alergens
import ListAlergensPage from './components/alergens/components/ListAlergensPage';
import AddAlergenPage from './components/alergens/components/AddAlergenPage';
import EditAlergenPage from './components/alergens/components/EditAlergenPage';
// Categories
import ListCategoriesPage from './components/categories/ListCategoriesPage';
import AddCategoryPage from './components/categories/AddCategoryPage';
import EditCategoryPage from './components/categories/EditCategoryPage';
// Categories hierarchy
import ListCategoriesHierarchyPage from './components/categoriesHierarchy/ListCategoriesHierarchyPage';
import AddCategoryHierarchyPage from './components/categoriesHierarchy/AddCategoryHierarchyPage';
import EditCategoryHierarchyPage from './components/categoriesHierarchy/EditCategoryHierarchyPage';

// Subcategories
import ListSubcategoriesPage from './components/subcategories/ListSubcategoriesPage';
import AddSubcategoryPage from './components/subcategories/AddSubcategoryPage';
import EditSubcategoryPage from './components/subcategories/EditSubcategoryPage';
// Products
import ListProductsPage from './components/products/list/ListProductsPage';
import AddProductPage from './components/products/AddProductPage';
import EditProductPage from './components/products/EditProductPage';
import AddBulkProductsPage from './components/products/AddBulkProductsPage';
// Tables
import ListTablesPage from './components/tables/ListTablesPage';
import AddTablePage from './components/tables/AddTablePage';
import EditTablePage from './components/tables/EditTablePage';
import AddBulkTablesPage from './components/tables/AddBulkTablesPage'

// Promo codes
import ListPromocodesPage from './components/promocodes/ListPromocodesPage';
import AddPromocodePage from './components/promocodes/AddPromocodePage';
import EditPromocodePage from './components/promocodes/EditPromocodePage';
import AddBulkPromocodesPage from './components/promocodes/AddBulkPromocodesPage';

// Table Groups
import ListTableGroupsPage from './components/table_groups/components/ListTableGroupsPage';
import AddTableGroupPage from './components/table_groups/components/AddTableGroupPage';
import EditTableGroupPage from './components/table_groups/components/EditTableGroupPage';

// Table Regions
import ListTableRegionsPage from './components/table_regions/components/ListTableRegionsPage';
import AddTableRegionPage from './components/table_regions/components/AddTableRegionPage';
import EditTableRegionPage from './components/table_regions/components/EditTableRegionPage';

// Table Halls
import ListRestaurantHallsPage from './components/table_halls/components/ListRestaurantHallsPage';
import AddRestaurantHallPage from './components/table_halls/components/AddRestaurantHallPage';
import EditRestaurantHallPage from './components/table_halls/components/EditRestaurantHallPage';

// Settings
import ListSettingsPage from './components/settings/components/ListSettingsPage';
import ListSettingsCategoriesPage from './components/settings/components/ListSettingsCategoriesPage';
import EditSettingsPage from './components/settings/components/EditSettingsPage';
//Ordertype prices and discounts
import OrdertypePricesPage from './components/settings/components/ordertype_prices/OrdertypePricesPage';
import OrdertypeDiscountsPage from './components/settings/components/ordertype_discounts/OrdertypeDiscountsPage';

import DeliveryRadiusesPage from './components/settings/components/delivery_radiuses/DeliveryRadiusesPage';
// Other
import GenerateShareableImagePage from './components/common/GenerateShareableImagePage';
import BulkAddProductsV2 from './components/common/BulkAddProductsV2';

//SUPER ADMIN
//PLACES
import SetCurrPlacePage from './components/places/components/SetCurrPlacePage';
import ListPlaceImagesPage from './components/place_images/components/ListImagesPage';
import AddPlaceImagesPage from './components/place_images/components/AddImagePage';
import EditPlaceImagesPage from './components/place_images/components/EditImagePage';

// STATISTICS
import StatisticsPage from './components/statistics/components/StatisticsPage';
import OrdersStatisticsPage from './components/statistics/components/orders/OrdersStatisticsPage';

import ListGeneralPromotionsPage from './components/generalPromotions/components/ListGeneralPromotionsPage';
import AddGeneralPromotionPage from './components/generalPromotions/components/AddGeneralPromotionPage';
import EditGeneralPromotionPage from './components/generalPromotions/components/EditGeneralPromotionPage';
// Settings - MENU
// Settings - MENU - Background image
// import EditMenuBackgroundImage from './components/settings/menu/EditMenuBackgroundImage';
// Settings - COMMON
// Settings - COMMON - Logo
// import EditCommonLogo from './components/settings/common/EditCommonLogo';
// Settings - Languages
// import ListLanguagesPage from './components/settings/languages/ListLanguagesPage'
// import AddLanguagePage from './components/settings/languages/AddLanguagePage'
// import EditLanguagePage from './components/settings/languages/EditLanguagePage'
// Users
import ListUsersPage from './components/users/ListUsersPage';
import ListRegisteredUsersPage from './components/users/registered/ListRegisteredUsersPage';
import AddUserPage from './components/users/AddUserPage';
import EditUserPage from './components/users/EditUserPage';
// Orders
import ListAllOrdersPage from './components/orders/ListAllOrdersPage';

import LoginPage from './components/login/Login';
import PrivateRoute from './utils/PrivateRoute';

// PLACES
import ListPlacesPage from './components/places/components/ListPlacesPage';
import AddPlacePage from './components/places/components/AddPlacePage';
import EditPlacePage from './components/places/components/EditPlacePage';

// INGREDIENTS
import ListIngredientsPage from './components/ingredients/components/ListIngredientsPage';
import AddIngredientPage from './components/ingredients/components/AddIngredientPage';
import EditIngredientPage from './components/ingredients/components/EditIngredientPage';

// CLIENTS
import ListClientsPage from './components/clients/components/ListClientsPage';
import AddClientPage from './components/clients/components/AddClientPage';
import EditClientPage from './components/clients/components/EditClientPage';

// REGIONS
import ListRegionsPage from './components/regions/components/ListRegionsPage';
import AddRegionPage from './components/regions/components/AddRegionPage';
import EditRegionPage from './components/regions/components/EditRegionPage';

import ListQuestionsPage from './components/feedback/components/ListQuestionsPage';
import AddQuestionPage from './components/feedback/components/AddQuestionPage';
import EditQuestionPage from './components/feedback/components/EditQuestionPage';
import ListAnswersPage from './components/feedback/components/ListAnswersPage';
// INVOICES
import ListInvoicesPage from './components/invoices/components/ListInvoicesPage';
import AddInvoicePage from './components/invoices/components/AddInvoicePage';
import EditInvoicePage from './components/invoices/components/EditInvoicePage';

// AUTO INVOICES
import AutoInvoicesPage from './components/autoInvoices/components/AutoInvoicesPage';

// PROFORMS
import ListProformsPage from './components/proforms/components/ListProformsPage';
import AddProformPage from './components/proforms/components/AddProformPage';
import EditProformPage from './components/proforms/components/EditProformPage';
//BOOKINGS
import ListBookingsPage from './components/bookings/components/ListBookingsPage';
import AddBookingRoomPage from './components/bookings/components/booking_rooms/AddBookingRoomPage';
import ListBookingRoomsPage from './components/bookings/components/booking_rooms/ListBookingRoomsPage';
import EditBookingRoomPage from './components/bookings/components/booking_rooms/EditBookingRoomPage';
//RESERVATIONS
import ListReservationsPage from './components/reservations/components/ListReservationssPage';
//REGISTERED USER DISCOUNT
import RegisteredUserDiscountPage from './components/registered_user_discount/components/RegisteredUserDiscountPage';

// DELIVERY SHAPES
import ListDeliveryShapesPage from './components/delivery_shapes/components/ListDeliveryShapesPage';
import AddDeliveryShapePage from './components/delivery_shapes/components/AddDeliveryShapePage';
import EditDeliveryShapePage from './components/delivery_shapes/components/EditDeliveryShapePage';

// WAREHOUSE SYSTEMS
import ListWarehouseSystemsPage from './components/warehouse_systems/components/ListWarehouseSystemsPage';
import PlaceWarehouseSystemPage from './components/warehouse_systems/components/PlaceWarehouseSystemPage';
// import EditDeliveryShapePage from './components/delivery_shapes/components/EditDeliveryShapePage';

// DELIVERY SYSTEMS
import PlaceDeliverySystemPage from './components/delivery_systems/components/PlaceDeliverySystemPage';
import PaymentProvidersPage from './components/payment_providers/components/PaymentProvidersPage';

import AccumulatedOrderAmountSettingPage from './components/accumulated_order_amount_setting/components/AccumulatedOrderAmountSettingPage';

import Utils from './utils/Utils';

// Reservations
// import ListPlaces from './reservations/places/components/ListPlaces';
// import ListRooms from './reservations/rooms/components/ListRooms';
// import AddRoom from './reservations/rooms/components/AddRoom';
// import EditRoom from './reservations/rooms/components/EditRoom';
// import ListReservationsPage from './reservations/reservations/components/ListReservationsPage';
import AddReservationPage from './reservations/reservations/components/AddReservationPage';
import EditReservationPage from './reservations/reservations/components/EditReservationPage';
// import ListReservationTablesPage from './reservations/tables/components/ListTablesPage';
// import AddReservationTablePage from './reservations/tables/components/AddTablePage';
// import EditReservationTablePage from './reservations/tables/components/EditTablePage';
// import ListReservationSchedule from './reservations/schedule/components/ListSchedulePage';
// import ListReservationSettingsPage from './reservations/settings/components/ListSettingsPage';
// import EditReservationSettingsPage from './reservations/settings/components/EditSettingsPage';
// import EditForbiddenEntityPage from './reservations/forbidden/components/EditForbiddenEntityPage';

const MyRouter = (props) => (
    <Router history={history}>
        <Switch>
            <PrivateRoute exact path='/admin' component={DashboardPage} />

            <Route exact path='/admin/login' component={LoginPage} />

            {/* {props.userRole == "ADMIN" ?  */}
            {/* <React.Fragment> */}

            {/* Alergens */}
            <PrivateRoute exact path='/admin/alergens' component={ListAlergensPage} />
            <PrivateRoute exact path='/admin/alergens/add' component={AddAlergenPage} />
            <PrivateRoute exact path='/admin/alergens/edit/:id' component={EditAlergenPage} />

            {/* Categories */}
            <PrivateRoute exact path='/admin/categories' component={ListCategoriesPage} />
            <PrivateRoute exact path='/admin/categories/add' component={AddCategoryPage} />
            <PrivateRoute exact path='/admin/categories/edit/:id' component={EditCategoryPage} />

            {/* Categories hierarchy*/}
            <PrivateRoute exact path='/admin/categories-hierarchy' component={ListCategoriesHierarchyPage} />
            <PrivateRoute exact path='/admin/categories-hierarchy/add' component={AddCategoryHierarchyPage} />
            <PrivateRoute exact path='/admin/categories-hierarchy/edit/:id' component={EditCategoryHierarchyPage} />


            {/* Subcategories */}
            <PrivateRoute exact path='/admin/subcategories' component={ListSubcategoriesPage} />
            <PrivateRoute exact path='/admin/subcategories/add' component={AddSubcategoryPage} />
            <PrivateRoute exact path='/admin/subcategories/edit/:id' component={EditSubcategoryPage} />

            {/* Products */}
            <PrivateRoute exact path='/admin/products' component={ListProductsPage} />
            <PrivateRoute exact path='/admin/products/add' component={AddProductPage} />
            <PrivateRoute exact path='/admin/products/edit/:id' component={EditProductPage} />
            <PrivateRoute exact path='/admin/products/add-bulk' component={AddBulkProductsPage} />


            {/* Tables */}
            <PrivateRoute exact path='/admin/tables' component={ListTablesPage} />
            <PrivateRoute exact path='/admin/tables/add' component={AddTablePage} />
            <PrivateRoute exact path='/admin/tables/edit/:id' component={EditTablePage} />
            <PrivateRoute exact path='/admin/tables/add-bulk' component={AddBulkTablesPage} />


            {/* Promocodes */}
            <PrivateRoute exact path='/admin/promocodes' component={ListPromocodesPage} />
            <PrivateRoute exact path='/admin/promocodes/add' component={AddPromocodePage} />
            <PrivateRoute exact path='/admin/promocodes/edit/:id' component={EditPromocodePage} />
            <PrivateRoute exact path='/admin/promocodes/add-bulk' component={AddBulkPromocodesPage} />

            {/* Settings */}
            <PrivateRoute exact path='/admin/settings' component={ListSettingsCategoriesPage} />
            <PrivateRoute exact path='/admin/settings/category/:catId' component={ListSettingsPage} />
            <PrivateRoute exact path='/admin/settings/category/:catId/setting/:id' component={EditSettingsPage} />

            <PrivateRoute exact path='/admin/settings/ordertype-prices/:orderType' component={OrdertypePricesPage} />
            <PrivateRoute exact path='/admin/settings/ordertype-discounts/:orderType' component={OrdertypeDiscountsPage} />

            <PrivateRoute exact path='/admin/settings/delivery-radiuses' component={DeliveryRadiusesPage} />

            {/* Other */}
            <PrivateRoute exact path='/admin/generate-shareable-image/category/:id' component={GenerateShareableImagePage} />

            <PrivateRoute exact path='/admin/bulk-add-products-v2/' component={BulkAddProductsV2} />

            {/* SUPER_ADMIN */}
            {/* PLACES */}
            <PrivateRoute exact path='/admin/places/current' component={SetCurrPlacePage} />
            <PrivateRoute exact path='/admin/places' component={ListPlacesPage} />
            <PrivateRoute exact path='/admin/places/add' component={AddPlacePage} />
            <PrivateRoute exact path='/admin/places/edit/:id' component={EditPlacePage} />

            <PrivateRoute exact path='/admin/place-images' component={ListPlaceImagesPage} />
            <PrivateRoute exact path='/admin/place-images/add' component={AddPlaceImagesPage} />
            <PrivateRoute exact path='/admin/place-images/edit/:id' component={EditPlaceImagesPage} />

            {/* CLIENTS */}
            <PrivateRoute exact path='/admin/clients' component={ListClientsPage} />
            <PrivateRoute exact path='/admin/clients/add' component={AddClientPage} />
            <PrivateRoute exact path='/admin/clients/edit/:id' component={EditClientPage} />

            <PrivateRoute exact path='/admin/ingredients' component={ListIngredientsPage} />
            <PrivateRoute exact path='/admin/ingredients/add' component={AddIngredientPage} />
            <PrivateRoute exact path='/admin/ingredients/edit/:id' component={EditIngredientPage} />

            {/* REGIONS */}
            <PrivateRoute exact path='/admin/regions' component={ListRegionsPage} />
            <PrivateRoute exact path='/admin/regions/add' component={AddRegionPage} />
            <PrivateRoute exact path='/admin/regions/edit/:id' component={EditRegionPage} />


            {/* Settings - MENU */}
            {/* Settings - MENU - Background image */}
            {/* <PrivateRoute exact path='/admin/settings/menu/background' component={EditMenuBackgroundImage} /> */}

            {/* Settings - MENU - Languages */}
            {/* <PrivateRoute exact path='/admin/settings/languages' component={ListLanguagesPage} />
                        <PrivateRoute exact path='/admin/settings/languages/add' component={AddLanguagePage} />
                        <PrivateRoute exact path='/admin/settings/languages/edit/:id' component={EditLanguagePage} /> */}

            {/* Settings - COMMON */}
            {/* Settings - COMMON - Logo */}
            {/* <PrivateRoute exact path='/admin/settings/common/logo' component={EditCommonLogo} /> */}

            {/* Users */}
            <PrivateRoute exact path='/admin/users' component={ListUsersPage} />
            <PrivateRoute exact path='/admin/users/registered' component={ListRegisteredUsersPage} />
            <PrivateRoute exact path='/admin/users/add' component={AddUserPage} />
            <PrivateRoute exact path='/admin/users/edit/:id' component={EditUserPage} />

            {/* Orders */}
            <PrivateRoute exact path='/admin/list-orders/:ordertype' component={ListAllOrdersPage} />


            <PrivateRoute exact path='/admin/statistics/users' component={StatisticsPage} />
            <PrivateRoute exact path='/admin/statistics/orders' component={OrdersStatisticsPage} />

            <PrivateRoute exact path='/admin/feedback/questions' component={ListQuestionsPage} />
            <PrivateRoute exact path='/admin/feedback/questions/add' component={AddQuestionPage} />
            <PrivateRoute exact path='/admin/feedback/questions/edit/:id' component={EditQuestionPage} />
            <PrivateRoute exact path='/admin/feedback/answers' component={ListAnswersPage} />
            {/* <PrivateRoute exact path='/admin/feedback/answers' component={ListQuestionsPage} /> */}

            <PrivateRoute exact path='/admin/invoices' component={ListInvoicesPage} />
            <PrivateRoute exact path='/admin/invoices/add' component={AddInvoicePage} />
            <PrivateRoute exact path='/admin/invoices/edit/:id' component={EditInvoicePage} />


            <PrivateRoute exact path='/admin/auto-invoices' component={AutoInvoicesPage} />


            <PrivateRoute exact path='/admin/proforms' component={ListProformsPage} />
            <PrivateRoute exact path='/admin/proforms/add' component={AddProformPage} />
            <PrivateRoute exact path='/admin/proforms/edit/:id' component={EditProformPage} />

            <PrivateRoute exact path='/admin/bookings' component={ListBookingsPage} />
            <PrivateRoute exact path='/admin/bookings/rooms' component={ListBookingRoomsPage} />
            <PrivateRoute exact path='/admin/bookings/rooms/add' component={AddBookingRoomPage} />
            <PrivateRoute exact path='/admin/bookings/rooms/edit/:id' component={EditBookingRoomPage} />

            <PrivateRoute exact path='/admin/tableGroups' component={ListTableGroupsPage} />
            <PrivateRoute exact path='/admin/tableGroups/add' component={AddTableGroupPage} />
            <PrivateRoute exact path='/admin/tableGroups/edit/:id' component={EditTableGroupPage} />

            <PrivateRoute exact path='/admin/reservations' component={ListReservationsPage} />

            <PrivateRoute exact path='/admin/general-promotions' component={ListGeneralPromotionsPage} />
            <PrivateRoute exact path='/admin/general-promotions/add' component={AddGeneralPromotionPage} />
            <PrivateRoute exact path='/admin/general-promotions/edit/:id' component={EditGeneralPromotionPage} />

            <PrivateRoute exact path='/admin/registered-user-discount' component={RegisteredUserDiscountPage} />

            <PrivateRoute exact path='/admin/tableRegions' component={ListTableRegionsPage} />
            <PrivateRoute exact path='/admin/tableRegions/add' component={AddTableRegionPage} />
            <PrivateRoute exact path='/admin/tableRegions/edit/:id' component={EditTableRegionPage} />

            <PrivateRoute exact path='/admin/restaurantHalls' component={ListRestaurantHallsPage} />
            <PrivateRoute exact path='/admin/restaurantHalls/add' component={AddRestaurantHallPage} />
            <PrivateRoute exact path='/admin/restaurantHalls/edit/:id' component={EditRestaurantHallPage} />


            <PrivateRoute exact path='/admin/delivery-shapes' component={ListDeliveryShapesPage} />
            <PrivateRoute exact path='/admin/delivery-shapes/add' component={AddDeliveryShapePage} />
            <PrivateRoute exact path='/admin/delivery-shapes/edit/:id' component={EditDeliveryShapePage} />

            <PrivateRoute exact path='/admin/warehouse-systems' component={ListWarehouseSystemsPage} />
            <PrivateRoute exact path='/admin/warehouse-systems/select' component={PlaceWarehouseSystemPage} />

            <PrivateRoute exact path='/admin/delivery-systems/select' component={PlaceDeliverySystemPage} />
            <PrivateRoute exact path='/admin/payment-providers/select' component={PaymentProvidersPage} />

            <PrivateRoute exact path='/admin/accumulated-order-amount-setting' component={AccumulatedOrderAmountSettingPage} />


            {/* <Route exact path='/signin' component={SignInPage} />
                <Route path='/cart' component={CartPage} />
                <Route exact path='/categories/:cat_id' component={ProductsListPage} />
                <Route exact path='/subcategories/:subcat_id/products' component={ProductsListPage} />
                <Route exact path='/products/:product_id' component={ProductViewPage} /> */}

            <PrivateRoute exact path='/admin/reservations/add' component={AddReservationPage} />
            <PrivateRoute exact path='/admin/reservations/add/:id/:hallId/:date' component={AddReservationPage} />
            <PrivateRoute exact path='/admin/reservations/edit/:id' component={EditReservationPage} />

            {/* Reservations */}
            {/* <PrivateRoute exact path='/admin/reservations-places' component={ListPlaces} />
                <PrivateRoute exact path='/admin/reservations-rooms' component={ListRooms} />
                <PrivateRoute exact path='/admin/reservations-rooms/add' component={AddRoom} />
                <PrivateRoute exact path='/admin/reservations-rooms/edit/:id' component={EditRoom} />
                <PrivateRoute exact path='/admin/reservations-reservations' component={ListReservationsPage} />
                <PrivateRoute exact path='/admin/reservations-reservations/add' component={AddReservationPage} />
                <PrivateRoute exact path='/admin/reservations-reservations/edit/:id' component={EditReservationPage} />
                <PrivateRoute exact path='/admin/reservations-tables' component={ListReservationTablesPage} />
                <PrivateRoute exact path='/admin/reservations-schedule' component={ListReservationSchedule} />
                <PrivateRoute exact path='/admin/reservations-tables/add' component={AddReservationTablePage} />
                <PrivateRoute exact path='/admin/reservations-tables/edit/:id' component={EditReservationTablePage} />
                <PrivateRoute exact path='/admin/reservations-settings' component={ListReservationSettingsPage} />
                <PrivateRoute exact path='/admin/reservations-settings/edit/:id' component={EditReservationSettingsPage} />
                <PrivateRoute exact path='/admin/reservations-forbidden/:type/:id' component={EditForbiddenEntityPage} /> */}

        </Switch>
    </Router>
)

const mapStateToProps = (state, dispatch) => {
    return {
        userRole: state.auth.user.role || null,
    }
};

export default connect(mapStateToProps, {})(MyRouter);

// export default MyRouter;

// const MyRouter = () => (
//         <Switch>
//             <PrivateRoute exact path='/admin' component={DashboardPage} />

//             <Route exact path='/admin/login' component={LoginPage} />

//             {/* Categories */}
//             <PrivateRoute exact path='/admin/categories' component={ListCategoriesPage} />
//             <PrivateRoute exact path='/admin/categories/add' component={AddCategoryPage} />
//             <PrivateRoute exact path='/admin/categories/edit/:id' component={EditCategoryPage} />

//             {/* Subcategories */}
//             <PrivateRoute exact path='/admin/subcategories' component={ListSubcategoriesPage} />
//             <PrivateRoute exact path='/admin/subcategories/add' component={AddSubcategoryPage} />
//             <PrivateRoute exact path='/admin/subcategories/edit/:id' component={EditSubcategoryPage} />

//             {/* Products */}
//             <PrivateRoute exact path='/admin/products' component={ListProductsPage} />
//             <PrivateRoute exact path='/admin/products/add' component={AddProductPage} />
//             <PrivateRoute exact path='/admin/products/edit/:id' component={EditProductPage} />

//             {/* Tables */}
//             <PrivateRoute exact path='/admin/tables' component={ListTablesPage} />
//             <PrivateRoute exact path='/admin/tables/add' component={AddTablePage} />
//             <PrivateRoute exact path='/admin/tables/edit/:id' component={EditTablePage} />

//             {/* Settings */}
//             <PrivateRoute exact path='/admin/settings' component={ListSettingsPage} />

//             {/* Settings - MENU */}
//             {/* Settings - MENU - Background image */}
//             <PrivateRoute exact path='/admin/settings/menu/background' component={EditMenuBackgroundImage} />

//             {/* Settings - MENU - Languages */}
//             <PrivateRoute exact path='/admin/settings/languages' component={ListLanguagesPage} />
//             <PrivateRoute exact path='/admin/settings/languages/add' component={AddLanguagePage} />
//             <PrivateRoute exact path='/admin/settings/languages/edit/:id' component={EditLanguagePage} />

//             {/* Settings - COMMON */}
//             {/* Settings - COMMON - Logo */}
//             <PrivateRoute exact path='/admin/settings/common/logo' component={EditCommonLogo} />

//             {/* Users */}
//             <PrivateRoute exact path='/admin/users' component={ListUsersPage} />
//             <PrivateRoute exact path='/admin/users/add' component={AddUserPage} />
//             <PrivateRoute exact path='/admin/users/edit/:id' component={EditUserPage} />

//             {/* Orders */}
//             <PrivateRoute exact path='/admin/orders' component={ListOrdersPage} />


//             {/* <Route exact path='/signin' component={SignInPage} />
//             <Route path='/cart' component={CartPage} />
//             <Route exact path='/categories/:cat_id' component={ProductsListPage} />
//             <Route exact path='/subcategories/:subcat_id/products' component={ProductsListPage} />
//             <Route exact path='/products/:product_id' component={ProductViewPage} /> */}

//             {/* Reservations */}
//             <PrivateRoute exact path='/admin/reservations-places' component={ListPlaces} />
//             <PrivateRoute exact path='/admin/reservations-rooms' component={ListRooms} />
//             <PrivateRoute exact path='/admin/reservations-rooms/add' component={AddRoom} />
//             <PrivateRoute exact path='/admin/reservations-rooms/edit/:id' component={EditRoom} />
//             <PrivateRoute exact path='/admin/reservations-reservations' component={ListReservationsPage} />
//             <PrivateRoute exact path='/admin/reservations-reservations/add' component={AddReservationPage} />

//         </Switch>
// )

// export default MyRouter;